<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="700"
      :persistent="load"
      scrollable>
      <v-card>
        <v-card-title>
          <v-btn right icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table      
            class="cebra elevation-2 mt-2"
            :headers="headers"
            :items="precioValor"
            :loading="load"
            :items-per-page="-1"
            hide-default-footer
            dense
            >
            <template v-slot:[`item.precio`]="{item}">
                <text-field-money-vue
                  v-model="item.precio"
                  class="d-inline-flex"
                  v-bind:properties="{
                    'prefix': '$',
                    'hide-details': true
                  }"
                />
            </template>
            <template v-slot:[`item.accion`]="{item}">
              <v-btn 
                icon color="success" title="Agregar"
                @click="$emit('agregarNva',item)">
                <v-icon>
                  fas fa-check
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No se encontraron datos
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            class="mr-2"
            color="secondary"
            :disabled="load"
            @click="dialog = false"
          >Volver</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextFieldMoneyVue from '../../components/util/TextFieldMoney.vue'

export default {
  name:'ModalPreciosLista',
  props:{
  value: Boolean,
  datos:{
    type: Object,
    default:{
      //precios: [],
      listasPrecio: [],
    }
  }
  },
  data(){
    return{
      load: false,
      precioValor: [],
      headers:[
        { text: 'Código Lista', value: 'lista_codigo', sortable: false },
        { text: 'Nombre Lista', value: 'lista_nombre', sortable: false },
        { text: 'Tipo Lista', value: 'tipo_lista_nombre', sortable: false },
        { text: 'Precio', value: 'precio', sortable: false, width: '170' },
        { text: 'Acción',value: 'accion', align: 'center', sortable: false, filterable: false}
      ]
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  components:{
    TextFieldMoneyVue
  },
  watch:{
    dialog(val){
      if(val){
        this.precioValor = [];
        for (const lista of this.datos.listasPrecio) {
          let unPrecio = JSON.parse(JSON.stringify(lista))
          unPrecio.precio = null;
          this.precioValor.push(unPrecio)
        }
      }
    }
  }
}
</script>

<style>

</style>